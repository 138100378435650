.FooterContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 18.25rem;
  width: 100vw;
  background-color: #0e0321;
  font-size: var(--phh4);
}

.FooterContainerScore {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  min-height: 18.25rem;
  width: 100vw;
  background: linear-gradient(
    179.69deg,
    #000 -266.02%,
    rgb(151 84 135) 135.67%
  );
  filter: drop-shadow(0 4px 4px rgb(0 0 0 / 25%));
}

.LogoContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding-left: 4%;
}

.Logo {
  width: 30rem;
  cursor: pointer;
}

.Powered {
  height: 2rem;
}

.LinksContainer {
  display: flex;
  flex-flow: column nowrap;
  padding-right: 6.5%;
}

.LinkRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
}

.SocialRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2.5%;
}
.SocialRow > a {
  margin-left: 4.5%;
}
.SocialRow > img {
  height: 1.5rem;
  cursor: pointer;
}

.LegalItem {
  padding-left: 10%;
  white-space: nowrap;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh4);
  text-align: end;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}

.LegalItem:hover {
  color: #ffde53;
}

@media screen and (max-width: 1920px) {
  .Logo {
    width: 23rem;
  }

  .LegalItem {
    font-size: var(--pbh4);
  }
}

@media screen and (max-width: 1440px) {
  .Logo {
    width: 20rem;
  }

  .LegalItem {
    font-size: var(--pmh4);
  }
}

@media screen and (max-width: 1280px) {
  .Logo {
    width: 15rem;
  }

  .LegalItem {
    font-size: var(--psh3);
  }
}

@media screen and (max-width: 1023px) {
  .Logo {
    width: 13rem;
  }

  .LegalItem {
    font-size: var(--th3);
  }
}

@media screen and (max-width: 768px) {
  .FooterContainer {
    flex-flow: column nowrap;
    justify-content: center;
  }

  .FooterContainerScore {
    flex-flow: column nowrap;
    justify-content: center;
  }

  .LogoContainer {
    flex-flow: column nowrap;
    padding-left: 0;
  }

  .Logo {
    width: 10rem;
    padding-bottom: 5%;
  }

  .Powered {
    margin-bottom: 10%;
  }

  .LinksContainer {
    padding-right: 0;
  }

  .SocialRow {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
    margin-bottom: 0;
  }

  .SocialRow > img {
    margin-right: 2.5%;
  }

  .LinkRow {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .LegalItem {
    padding-left: 0;
    margin-bottom: 10%;
  }
}
