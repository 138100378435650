.MarketHomeBackground {
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgb(14 3 33),
    rgb(18 0 61 / 90%) 14.84%,
    rgb(29 4 89 / 90%) 21.91%,
    rgb(39 9 114 / 90%) 29.12%
  );
  display: flex;
  justify-content: space-evenly;
}

.BuyContainer {
  min-height: max-content;
  display: flex;
  flex-flow: column nowrap;
  width: 40rem;
  background: #090117;
  margin-top: 1%;
  margin-bottom: 1%;
  padding: 1.5% 3% 1.5% 3%;
  font-size: var(--phh4);
}

.ExitArrow {
  display: flex;
  align-items: flex-start;
  align-items: center;
  width: 100%;
  padding: 0;

  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);
  letter-spacing: 0.15px;

  color: #ffffff;
}

.ExitArrow > a > img {
  width: 2rem;
}

.ExitArrow > a {
  margin-right: 5%;
  background: none;
  border: none;
}

.Title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 2%;
}

.MintTitle {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  color: #ffde53;
}

.PriceTitle {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  text-transform: uppercase;

  color: #ffffff;
}

.Subtitle {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  color: #ffffff;
}

.Body {
  height: 85%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.CharacterBanner {
  width: 50%;
  margin-bottom: 0.5rem;
  box-shadow: 0px 9.90767px 26.4205px rgba(50, 53, 64, 0.08),
    0px 9.90767px 26.4205px rgba(50, 53, 64, 0.12);
  border-radius: 4.95383px;
  border: 0.1rem solid white;
}

.Banner {
  padding-top: 2%;
  margin-bottom: 0.5rem;
}

.PriceMessage {
  margin-bottom: 0.5rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.15em;

  color: #ffffff;
}

.Price {
  margin-bottom: 0.5rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  text-align: center;
  letter-spacing: 0.15em;

  color: #ffde53;
}

.Amount {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 10rem;
  padding-top: 2%;
  padding-bottom: 2%;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  border: 0.2rem solid #5532aa;
  align-items: center;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  text-align: center;
  letter-spacing: 0.15em;

  color: #ffffff;
}

.Amount > button {
  margin-left: 15%;
  margin-right: 15%;
  color: #cebef7;
  background: none;
  border: none;
}

.Resume {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  height: max-content;
  border-radius: 1rem;
  border: 0.2rem solid #5532aa;
  padding: 2.5%;
  margin-bottom: 0.5rem;
  background: #0e0321;
  box-shadow: inset 0px -0.783617px 0px rgba(0, 0, 0, 0.25);
}

.ResumeItem {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 20rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  color: #ffffff;
}

.ResumeTotal {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  color: #ffde53;
}

.MintButton {
  padding: 1.5rem 3rem 1.5rem 3rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;

  text-align: center;
  letter-spacing: 0.01em;

  color: #000000;
  border: none;
  background: #ccff88;
}

.Network {
  padding-bottom: 2%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;

  text-align: center;
  letter-spacing: 0.04em;

  color: #ffffff;
}

@media screen and (max-width: 1920px) {
  .BuyContainer {
    min-height: max-content;
    width: 40rem;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: var(--pbh4);
  }

  .MintButton {
    padding: 1rem 2rem 1rem 2rem;
  }

  .ExitArrow {
    font-size: var(--pbh3);
  }

  .MintTitle {
    font-size: var(--pbh3);
  }

  .Amount > button {
    font-size: var(--pbh3);
  }
}

@media screen and (max-width: 1440px) {
  .BuyContainer {
    width: 40rem;
    font-size: var(--pmh4);
  }

  .ExitArrow {
    font-size: var(--pmh3);
  }

  .MintTitle {
    font-size: var(--pmh3);
  }

  .Amount > button {
    font-size: var(--pmh3);
  }
}

@media screen and (max-width: 1280px) {
  .BuyContainer {
    font-size: var(--psh4);
  }

  .ExitArrow {
    font-size: var(--psh3);
  }

  .MintTitle {
    font-size: var(--psh3);
  }

  .MintButton {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .Amount > button {
    font-size: var(--psh3);
  }

  .ExitArrow > a > img {
    width: 1rem;
  }
}

@media screen and (max-width: 1023px) {
  .BuyContainer {
    font-size: var(--th4);
  }

  .ExitArrow {
    font-size: var(--th3);
  }

  .MintTitle {
    font-size: var(--th3);
  }

  .Amount > button {
    font-size: var(--th3);
  }
}

@media screen and (max-width: 768px) {
  .BuyContainer {
    min-height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 5%;
    font-size: var(--mh4);
  }

  .ExitArrow {
    font-size: var(--mh3);
  }

  .MintTitle {
    font-size: var(--mh3);
  }

  .Amount > button {
    font-size: var(--mh3);
  }
}
