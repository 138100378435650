.CardContainer {
  width: max-content;
  margin-top: 2rem;
  padding-top: 5rem;
  height: 20rem;
}

.Card {
  width: 25rem;
  height: 9rem;
  border-radius: 6px;
  border: 0.25rem solid black;
  overflow: hidden;
}

.Card:hover {
  animation: increase 0.75s forwards;
}

@keyframes increase {
  100% {
    height: 18rem;
    background-color: rgb(255, 255, 0);
  }
}

.Showcase {
  display: grid;
  grid-template-columns: auto 50%;
  justify-content: space-between;
  height: 8.5rem;
}

.Data {
  padding-left: 1.5rem;
  width: 13rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.Name {
  height: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  letter-spacing: 0.15em;
  color: #000;
}

.Id {
  height: 2rem;
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  letter-spacing: 0.15em;
  color: #000;
}

.Thumbnail {
  position: absolute;
  margin-left: 12rem;
  z-index: 2;
  bottom: 6.25rem;
  width: 12.5rem;
}

.Score {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: 1.2rem;
  color: #000;
}

.ViewButton {
  z-index: 3;
  border-radius: 0.3rem;
  width: max-content;
  padding: 4% 3% 3% 3%;
  margin: 0;
  border: 0.15rem solid black;
  text-decoration: none;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #000;
}

.ViewButton:hover {
  color: white;
  background-color: black;
}

.Description {
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 1% 6% 1% 6%;
  margin: 0;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: 0.8rem;
  letter-spacing: 0.05em;
  color: #000;
}

.Skills {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1%;
  padding-top: 0.5%;
  background-color: black;
}
