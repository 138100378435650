.BoardContainer {
  display: inline-block;
  width: 100%;
}

.PaginationArrows {
  margin-top: 2%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.LeftArrow {
  margin-right: 2%;
  border: none;
  background: none;
}

.RightArrow {
  margin-left: 2%;
  border: none;
  background: none;
}

.RightArrow > img {
  width: 2rem;
}

.LeftArrow > img {
  width: 2rem;
}

.CurrentPage {
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh4);
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}

@media screen and (max-width: 1920px) {
  .CurrentPage {
    font-size: var(--pbh4);
  }
}

@media screen and (max-width: 1440px) {
  .CurrentPage {
    font-size: var(--pmh4);
  }
}

@media screen and (max-width: 1280px) {
  .CurrentPage {
    font-size: var(--psh4);
  }

  .RightArrow > img {
    width: 1rem;
  }

  .LeftArrow > img {
    width: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .CurrentPage {
    font-size: var(--th4);
  }
}

@media screen and (max-width: 758px) {
  .CurrentPage {
    font-size: var(--mh4);
  }
}
