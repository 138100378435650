.BackgroundContainer {
  width: 25.05rem;
  background-color: yellow;
  border-radius: 5.03632px;
  border: 0.2rem solid black;
}

.Top {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-left: 1em;
  height: 5.5rem;
}

.Title {
  width: 100%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  letter-spacing: 0.15em;

  color: #000000;
}

.Price {
  width: 100%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: 0.75rem;
  letter-spacing: 0.15em;
  color: #000000;
}

.Bottom {
  height: 10.55rem;
  border: 0.2rem solid black;
  padding: 0;
}

.Background {
  max-width: 100%;
}

.ButtonsContainer {
  position: absolute;
  width: 25.05rem;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  padding-bottom: 3%;
  z-index: 1;
}

.Amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 5rem;
  height: 2rem;

  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.15em;

  color: #fff;
  background: #5532aa;
  border-radius: 7px;
  border: 0.15rem solid #12a9a9;
}

.AmountButton {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.15em;
  background: none;
  border: none;

  color: #fff;
}

.AmountText {
  padding-top: 0.1rem;
}

.BuyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000000;
  border: 0.2rem solid black;
  padding-top: 0.2rem;

  width: 6em;
  height: 2rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  text-align: center;
  letter-spacing: 0.01em;
  border-radius: 7px;
  color: black;
  background-color: #cf8;
  text-transform: uppercase;
}

.BuyButton:hover {
  color: black;
}
