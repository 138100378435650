html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0 !important;
  overflow-x: hidden;

  --pixel-border: black;

  /* ↑ Inner border color */

  --pixel-border-3: var(--pixel-border);

  /* ↑ Outer border color */

  --pixel: 0.125rem;

  /* pc huge 4k typography */
  --phh1: 5rem;
  --phh2: 4.375rem;
  --phh2Modals: 2.875rem;
  --phh3: 2.5rem;
  --phh4: 1.625rem;
  --phh5: 1.25rem;

  /* pc big 1920 typography */
  --pbh1: 2.75rem;
  --pbh2: 2.12rem;
  --pbh2Modals: 1.625rem;
  --pbh3: 1.25rem;
  --pbh4: 0.875rem;

  /* pc medium 1440 typography */
  --pmh1: 2.5rem;
  --pmh2: 1.875rem;
  --pmh2Modals: 1.5rem;
  --pmh3: 1rem;
  --pmh4: 0.75rem;

  /* pc small 1280 typography */
  --psh1: 2rem;
  --psh2: 1.425rem;
  --psh2Modals: 1.25rem;
  --psh3: 0.85rem;
  --psh4: 0.68rem;

  /* tablet 1023 typography */
  --th1: 1.875rem;
  --th2: 1.25rem;
  --th2Modals: 1rem;
  --th3: 0.75rem;
  --th4: 0.625rem;

  /* Mobile 768 typography */
  --mh1: 1.5rem;
  --mh2: 0.625rem;
  --mh2Modals: 0.82rem;
  --mh3: 0.625rem;
  --mh4: 0.5rem;
}

@font-face {
  font-family: 'Pixelic War';
  src: local('Pixelic-War'),
    url('../public/fonts/Pixelic-War.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'xpaider pixel explosion 02';
  src: local('xpaider-pixel-explosion'),
    url('../public/fonts/xpaider-pixel-explosion.TTF') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Pixeled;
  src: local('Pixeled'), url('../public/fonts/Pixeled.ttf') format('truetype');
  font-weight: 400;
}
