.BoardPopUp {
  display: inline-block;
  max-height: 75vh;
  width: 45vw;
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;

  scrollbar-color: #ccff88 #5532aa;
}

.BoardPopUp::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5532aa;
  border-radius: 10px;
}

.BoardPopUp::-webkit-scrollbar {
  width: 12px;
}

.BoardPopUp::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccff88;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.BoardPopUp span {
  text-transform: uppercase;
}

.Header {
  display: grid;
  grid-template-columns: 29% 71%;
  align-items: center;
  padding: 2vmin;
  background-color: #5532aa;
  border: 2px solid white;
  border-radius: 8px;
  overflow: hidden;
  color: white;
}

.Title {
  font-size: var(--phh3);
  letter-spacing: 0.15em;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
}

.Subtitle {
  font-size: var(--phh5);
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
}

.Subtitle {
  letter-spacing: 0.15em;
}

.DescriptionContainer {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-rows: auto auto auto auto auto auto auto;
  padding: 1% 2vmin;
  background-color: #09001c;
}

.DescriptionSeparator {
  display: flex;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 3;
}

.SeparatorLine {
  height: 0.1rem;
  width: 100%;
  background: #1d0159;
}

.ItemTitle {
  display: flex;
  align-items: center;
  height: 4rem;
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--th4);
  white-space: normal;
  text-transform: uppercase;
  color: #ffde53;
}

.ItemContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Pixeled;
  font-size: var(--th4);
  font-style: normal;
  font-weight: 400;

  letter-spacing: 0.15em;
  color: white;
}

.ItemAddress {
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-family: Pixeled;
  font-size: var(--th4);
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.15em;
  color: white;
}
.SkillBox {
  display: flex;
  align-items: center;
  width: 55%;
}
.HistoryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0.5% 2vmin;
  background-color: #162700;
}

.HistoryTitle {
  width: 100%;
  padding: 5%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);
  text-transform: uppercase;
  color: #fff;
}

.Thumbnail {
  height: 3rem;
  padding-right: 5%;
}

@media screen and (max-width: 1920px) {
  .BoardPopUp {
    font-size: var(--pbh3);
    width: 55vw;
  }

  .Title {
    font-size: var(--pbh2Modals);
  }

  .HistoryTitle {
    font-size: var(--pbh2Modals);
  }
}

@media screen and (max-width: 1440px) {
  .BoardPopUp {
    font-size: var(--pmh3);
    width: 65vw;
  }

  .Title {
    font-size: var(--pmh2Modals);
  }

  .HistoryTitle {
    font-size: var(--pmh2Modals);
  }
}

@media screen and (max-width: 1280px) {
  .BoardPopUp {
    font-size: var(--psh3);
    width: 70vw;
  }

  .Title {
    font-size: var(--psh2Modals);
  }

  .HistoryTitle {
    font-size: var(--psh2Modals);
  }
}

@media screen and (max-width: 1023px) {
  .DescriptionContainer {
    grid-template-columns: 40% 60%;
  }

  .BoardPopUp {
    font-size: var(--th3);
    width: 75vw;
  }

  .ItemContent {
    padding-left: 0;
    padding-bottom: 1vmin;
    padding-top: 1vmin;
  }

  .Title {
    font-size: var(--th1);
  }

  .HistoryBoard {
    display: grid;
    grid-template-columns: 50% auto auto auto;
    justify-content: center;
  }

  .HistoryTitle {
    font-size: var(--th1);
  }
}

@media screen and (max-width: 768px) {
  .DescriptionContainer {
    grid-template-columns: 45% 55%;
  }

  .BoardPopUp {
    max-height: 85vh;
    font-size: var(--mh3);
    width: 80vw;
  }
  .Title {
    font-size: var(--mh1);
  }

  .HistoryBoard {
    display: grid;
    grid-template-columns: auto auto;
  }

  .HistoryTitle {
    font-size: var(--mh1);
  }
}
