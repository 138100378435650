.LightNavbar {
  background-color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  flex-wrap: nowrap;
  white-space: nowrap;
  padding-right: 0 !important;
}

.DarkNavbar {
  background-color: #0e0321;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.Button,
.Button:hover,
.Button:focus,
.Button:active:focus {
  border: none;
  background: none;
  box-shadow: none;
}

.Nav {
  min-width: max-content;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.Logo {
  width: 18rem;
  padding-top: 0.5%;
  cursor: pointer;
}

.Container {
  max-width: 90vw;
  justify-content: space-evenly;
  margin-right: 4vw;
}

.Element {
  width: max-content;
  margin-left: 1vw;
  text-align: center;
  text-decoration: none;
  font-size: var(--phh4);
  font-family: 'xpaider pixel explosion 02';
  color: #b3b3b3 !important;
}

.SelectedElement {
  margin-left: 1vw;
  text-align: center;
  text-decoration: none;
  font-size: var(--phh4);
  font-family: 'xpaider pixel explosion 02';
  color: #ffde53 !important;
}

.Element:hover {
  color: #ffde53 !important;
}

.Element:active {
  color: #ffde53 !important;
}

.Element:focus {
  color: #ffde53 !important;
}

.BalanceElement {
  margin-left: 1vw;
  margin-right: 1vw;

  text-decoration: none;
  font-size: var(--phh4);
  font-family: 'xpaider pixel explosion 02';
  color: #b3b3b3 !important;
}

.BalanceElement:hover {
  color: #ffde53 !important;
}

.RewardsElement {
  margin-left: 1vw;
  text-decoration: none;
  font-size: var(--phh4);
  font-family: 'xpaider pixel explosion 02';
  color: #ffde53 !important;
}

.RewardsElement:hover {
  color: #fff !important;
}

.ModalContent {
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConnectButton {
  height: 3rem;
  width: 30rem;
  margin: 2%;
  white-space: nowrap;
  font-size: var(--phh4);
  font-family: 'xpaider pixel explosion 02';
  color: black;
  background: #b3b3b3;
  text-align: start;
  padding-left: 7.5rem;
  border: 0.25rem solid black;
  border-radius: 0.5rem;
}

.ConnectButton:hover {
  background: #ccff88;
}

.LoginLogo {
  height: 2rem;
  margin-right: 2rem;
}

.Drop {
  margin-left: 1rem;
}

.Drop > button {
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.65rem;
}

.Toggle {
  border: none;
}

.Toggle::after {
  content: none;
}

.Menu {
  display: flex;
  flex-flow: column nowrap !important;
  align-items: start;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #1b0442;
}

.Address {
  width: max-content;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.3rem;
  text-decoration: none;
  font-size: var(--phh4);
  font-family: 'xpaider pixel explosion 02';
  color: #b3b3b3 !important;
  cursor: default;
}

.Profile {
  width: 1.5rem;
}
.Logout {
  margin-left: 1rem;
  width: 1.5rem;
}

.AddressRow {
  margin-left: 1vw;
  margin-right: 1vw;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1920px) {
  .Logo {
    width: 15rem;
  }

  .Element {
    font-size: var(--pbh3);
  }

  .SelectedElement {
    font-size: var(--pbh3);
  }

  .RewardsElement {
    font-size: var(--pbh3);
  }

  .BalanceElement {
    font-size: var(--pbh3);
  }

  .Address {
    font-size: var(--pbh3);
  }
}

@media screen and (max-width: 1440px) {
  .Logo {
    width: 13rem;
  }

  .Element {
    font-size: var(--pmh3);
  }

  .SelectedElement {
    font-size: var(--pmh3);
  }

  .RewardsElement {
    font-size: var(--pmh3);
  }

  .BalanceElement {
    font-size: var(--pmh3);
  }

  .Address {
    font-size: var(--pmh3);
  }
}

@media screen and (max-width: 950px) {
  .Logo {
    width: 10rem;
  }
  .Nav {
    flex-direction: column;
    width: 100%;
  }

  .LightNavbar {
    flex-wrap: wrap;
  }

  .DarkNavbar {
    flex-wrap: wrap;
  }

  .Element {
    padding-top: 1rem;
    margin-left: 0;

    font-size: var(--th3);
  }

  .SelectedElement {
    padding-top: 1rem;
    margin-left: 0;

    font-size: var(--th3);
  }

  .RewardsElement {
    padding-top: 1rem;
    margin-left: 0;

    font-size: var(--th3);
  }

  .BalanceElement {
    padding-top: 1rem;
    margin-left: 0;

    font-size: var(--th3);
  }

  .Address {
    font-size: var(--th3);
  }
}

@media screen and (max-width: 768px) {
  .Element {
    font-size: var(--mh3);
  }

  .SelectedElement {
    font-size: var(--mh3);
  }

  .RewardsElement {
    font-size: var(--mh3);
  }

  .BalanceElement {
    font-size: var(--mh3);
  }

  .Address {
    font-size: var(--mh3);
  }
}
