.GreenButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  background: #ccff88;
  --pixel-bg: #ccff88;
  margin-bottom: 0.15rem;

  box-shadow:
	/* Inner Background Color */ 0 calc(var(--pixel) * -3) 0
      calc(var(--pixel) * -1) var(--pixel-bg),
    0 calc(var(--pixel) * 3) 0 calc(var(--pixel) * -1) var(--pixel-bg),
    /* Border Layer 3: --pixel-border-3 */ calc(var(--pixel) * -3) 0 0 0
      var(--pixel-border-3),
    calc(var(--pixel) * 3) 0 0 0 var(--pixel-border-3),
    0 0 0 calc(var(--pixel) * 2) var(--pixel-border-3),
    0 calc(var(--pixel) * -3) 0 var(--pixel) var(--pixel-border-3),
    0 calc(var(--pixel) * 3) 0 var(--pixel) var(--pixel-border-3);
}

.GreenButtonInactive {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  background: #828282;
  --pixel-bg: #828282;
  margin-bottom: 0.15rem;

  box-shadow:
	/* Inner Background Color */ 0 calc(var(--pixel) * -3) 0
      calc(var(--pixel) * -1) var(--pixel-bg),
    0 calc(var(--pixel) * 3) 0 calc(var(--pixel) * -1) var(--pixel-bg),
    /* Border Layer 3: --pixel-border-3 */ calc(var(--pixel) * -3) 0 0 0
      var(--pixel-border-3),
    calc(var(--pixel) * 3) 0 0 0 var(--pixel-border-3),
    0 0 0 calc(var(--pixel) * 2) var(--pixel-border-3),
    0 calc(var(--pixel) * -3) 0 var(--pixel) var(--pixel-border-3),
    0 calc(var(--pixel) * 3) 0 var(--pixel) var(--pixel-border-3);
}

.GreenButton:hover {
  background: #828282;
  --pixel-bg: #828282;
}

.GreenButtonText {
  text-align: center;
  text-decoration: none;
  font-family: 'xpaider pixel explosion 02';
  font-size: var(--phh4);
  white-space: nowrap;
  margin: 0.3rem 1rem 0.1rem 1rem;
  text-transform: uppercase;
  color: #000000;
  font-weight: 400;
  letter-spacing: 0.08rem;
}

.GreenButtonText:hover {
  color: #000000;
}

@media screen and (max-width: 1920px) {
  .GreenButtonText {
    font-size: var(--pbh3);
  }
}

@media screen and (max-width: 1440px) {
  .GreenButtonText {
    font-size: var(--pmh3);
  }
}

@media screen and (max-width: 950px) {
  .GreenButtonText {
    font-size: var(--th3);
  }
}

@media screen and (max-width: 768px) {
  .GreenButtonText {
    font-size: var(--mh3);
  }
}
