.AudioCardContainer {
  height: 13rem;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #0e0321;
  box-shadow: 0 11.2461px 29.9895px rgb(50 53 64 / 8%),
    0 11.2461px 29.9895px rgb(50 53 64 / 12%);
  border-radius: 5.62px;
  border: 0.1rem solid white;
}

.AudioCardContainerPlay {
  width: 13rem;
  height: 13rem;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #002121;
  box-shadow: 0 11.2461px 29.9895px rgb(50 53 64 / 8%),
    0 11.2461px 29.9895px rgb(50 53 64 / 12%);
  border-radius: 5.62px;
  border: 0.1rem solid white;
  background: #002121;
}

.Title {
  padding-top: 4%;
  padding-left: 5%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.15em;
  color: #fff;
}
.Subtitle {
  padding-bottom: 1%;
  padding-left: 5%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.15em;
  color: #fff;
}

.AudioCardSubcontainer {
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 3.62px;
  border: 0.1rem solid white;
}

.CharacterName {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
  letter-spacing: 0.03em;

  color: #ffffff;
}

.AudioButton {
  background: none;
  border: none;

  width: 50%;
  height: 50%;
}

.BuyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000000;
  border: 0.2rem solid black;
  padding-top: 0.2rem;
  width: 6em;
  height: 2rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  text-align: center;
  letter-spacing: 0.01em;
  border-radius: 7px;
  color: black;
  background-color: #cf8;
  text-transform: uppercase;
}

.BuyButton:hover {
  color: black;
}
