.CustomSelectorContainer {
  margin-left: 2rem;
}

.Item {
  background: none;
  border: none;

  padding: 5%;

  text-align: center;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.03em;

  color: white;
}

.Item:focus {
  color: #ccff88;
}

.Toggle {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;

  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  text-transform: uppercase;
  background: #1b0442;
  border: none;
}

.Toggle:hover,
.Toggle:focus {
  background: #1b0442;
}

.Menu {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 8rem;
  width: 100%;
  background: #1b0442;
}

@media screen and (max-width: 1440px) {
  .Toggle {
    font-size: 0.85rem;
  }

  .Item {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 1023px) {
  .Toggle {
    font-size: 0.8rem;
  }

  .Item {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .CustomSelectorContainer {
    margin-left: 0;
    margin-top: 1rem;
  }

  .Toggle {
    font-size: 0.7rem;
  }

  .Item {
    font-size: 0.7rem;
  }
}
