.ItemContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 4rem;
  text-transform: uppercase;
}

.NameBlock {
  display: flex;
  flex-direction: column;
  width: 25%;
  flex-shrink: 1;
}

.SkillBox {
  width: 45%;
  flex-shrink: 0;
}

.Name {
  width: 100%;
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--pbh4);
  color: #fff;
}

.Result {
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh5);
  white-space: nowrap;
  color: #fff;
  width: 5%;
}

.ItemSeparator {
  height: 0.1rem;
  width: 100%;
  background: #62675c;
}

.Thumbnail {
  height: 2rem;
}

@media screen and (max-width: 1920px) {
  .Name {
    font-size: var(--pbh4);
  }

  .Time {
    font-size: var(--pbh4);
  }

  .Result {
    font-size: var(--pbh4);
  }
}

@media screen and (max-width: 1440px) {
  .Name {
    font-size: var(--pmh4);
  }

  .Time {
    font-size: var(--pmh4);
  }

  .Result {
    font-size: var(--pmh4);
  }
}

@media screen and (max-width: 1280px) {
  .Name {
    font-size: var(--psh4);
  }

  .Time {
    font-size: var(--psh4);
  }

  .Result {
    font-size: var(--psh4);
  }

  .SkillBox {
    width: 55%;
  }

  .NameBlock {
    width: 15%;
  }
}

@media screen and (max-width: 1023px) {
  .ItemContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 15%;
  }

  .NameBlock {
    text-align: center;
    width: 50%;
    margin: 5%;
  }

  .SkillBox {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .ItemSeparator {
    height: 0;
    width: 0;
    background: none;
  }

  .Name {
    font-size: var(--th4);
  }

  .Time {
    font-size: var(--th4);
  }

  .Result {
    font-size: var(--th4);
  }
}

@media screen and (max-width: 768px) {
  .Name {
    font-size: var(--mh4);
  }

  .Time {
    font-size: var(--mh4);
  }

  .Result {
    font-size: var(--mh4);
  }
}
