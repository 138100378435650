.HomeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-left: 5%;
  padding-right: 5%;
  background: linear-gradient(
    180deg,
    #0e0321 2.12%,
    rgb(18 0 61 / 90%) 14.84%,
    rgb(29 4 89 / 90%) 21.91%,
    rgb(39 9 114 / 90%) 29.12%,
    rgb(61 25 130 / 90%) 38.32%,
    rgb(151 84 135 / 90%) 135.67%
  );
}

.BannerContainer {
  min-height: 25vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.BannerTop {
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  width: 20%;
}

.BoardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 75vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.TabContainer {
  display: inline;
  align-self: center;
  margin-top: 2.5%;
}

.HorizontalSeparator {
  background: rgba(120, 120, 120, 0.9);
  height: 0.1rem;
  width: 100%;
}

.TabButton {
  margin-right: 3.5vw;
  margin-left: 3.5vw;
  margin-bottom: 1rem;
  background: none;
  border: none;
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh4);
  text-transform: uppercase;
}

.TabButton:focus {
  outline: none;
}

@media screen and (max-width: 1920px) {
  .BoardsSubContainer {
    font-size: var(--pbh4);
  }

  .TabButton {
    font-size: var(--pbh4);
    margin-left: 0;
  }

  .TabContainer {
    display: inline;
    align-self: flex-start;
  }
}

@media screen and (max-width: 1440px) {
  .BoardsSubContainer {
    font-size: var(--pmh4);
  }

  .TabButton {
    font-size: var(--pmh4);
    margin-left: 0;
  }

  .TabContainer {
    display: inline;
    align-self: flex-start;
  }
}

@media screen and (max-width: 1280px) {
  .BoardsSubContainer {
    font-size: var(--psh4);
  }

  .TabButton {
    font-size: var(--psh4);
    margin-left: 0;
  }

  .TabContainer {
    display: inline;
    align-self: flex-start;
  }
}

@media screen and (max-width: 980px) {
  .BoardsSubContainer {
    font-size: var(--th4);
  }

  .TabButton {
    font-size: var(--th4);
  }
}

@media screen and (max-width: 768px) {
  .BoardsSubContainer {
    font-size: var(--mh4);
  }

  .TabButton {
    font-size: var(--mh4);
  }
}
