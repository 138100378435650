.NotificationContainer {
  display: flex;
  height: 37rem;
  width: 54rem;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);
  text-align: center;
  align-items: center;
  background: #fff;
  border: 0.35rem solid black;
  border-radius: 8px;
  padding: 2.5%;
}

.Message {
  width: 75%;
  font-weight: 800;
  color: #000000;
  text-transform: uppercase;
}

.CrossButton {
  color: black;
  background: none;
  border: none;
  align-self: flex-end;
  text-transform: uppercase;
}

.ConnectButton {
  margin: 1.5% 1.5% 0 1.5%;
  padding: 2.5%;
  border-radius: 7px;
  border: 0.2rem solid black;
  letter-spacing: 0.08em;
  background: #5532aa;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;
}

.CancelButton {
  margin: 1.5% 1.5% 0 1.5%;
  padding: 2.5%;
  border-radius: 7px;
  border: 0.2rem solid black;
  white-space: nowrap;
  letter-spacing: 0.08em;
  background: none;
  color: black;
  text-transform: uppercase;
}

.Row {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1920px) {
  .NotificationContainer {
    height: 20rem;
    width: 27rem;
    font-size: var(--pbh3);
  }
}

@media screen and (max-width: 1440px) {
  .NotificationContainer {
    height: 16rem;
    width: 23rem;
    font-size: var(--pmh3);
  }
}

@media screen and (max-width: 1280px) {
  .NotificationContainer {
    height: 14rem;
    width: 21rem;
    font-size: var(--psh3);
  }
}

@media screen and (max-width: 1023px) {
  .NotificationContainer {
    height: 12rem;
    width: 19rem;
    font-size: var(--th3);
  }
}

@media screen and (max-width: 768px) {
  .NotificationContainer {
    height: 10rem;
    width: 14rem;
    font-size: var(--mh3);
  }
}
