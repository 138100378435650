.Container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100vw;
  background: linear-gradient(
    360deg,
    rgb(14 3 33 / 90%) 2.12%,
    rgb(18 0 61 / 90%) 14.84%,
    rgb(29 4 89 / 90%) 21.91%,
    rgb(39 9 114 / 90%) 29.12%,
    rgb(61 25 130 / 90%) 38.32%,
    rgb(151 84 135 / 90%) 135.67%
  );
  padding-top: 5%;
}

.ItemsContainer {
  display: flex;
  flex-direction: column;
  min-height: 66vh;
  padding-bottom: 1rem;
  width: 100vw;
}

.ItemsTitle {
  display: inline;
  text-align: center;
  padding-top: 8rem;
  padding-bottom: 6rem;
  width: 100vw;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh2);
  text-transform: uppercase;
  color: #7cffc2;
  padding-bottom: 5%;
}

.ItemsSubContainer {
  display: flex;
  flex-wrap: nowrap;
  min-height: 25vh;
  width: 100vw;
}

.Item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 100%;
  height: 100%;
}

.ItemImage {
  width: 12.5rem;
  height: 12.5rem;
}

.Description {
  width: 75%;
  padding-top: 5vh;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);
  text-align: center;
  color: #fff;
}

.ScoreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  min-height: 100vh;
}

.ScoreTitle {
  display: inline;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 6rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh2);
  text-transform: uppercase;
  color: #7cffc2;
  padding-top: 5%;
  padding-bottom: 5%;
}

.ScoreBoard {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.ScoreButton {
  display: inline;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 800;
  font-size: var(--phh3);
  text-align: center;
  letter-spacing: 0.08em;
  color: #000;
  padding-top: 5%;
}

.MeetContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5vh auto;
  min-height: 81vh;
  width: 100vw;
}

.MeetTitle {
  display: inline;
  text-align: center;
  margin-top: 3rem;
  padding-bottom: 3rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh2);
  text-transform: uppercase;
  color: #7cffc2;
  padding-bottom: 5%;
}

.Line {
  padding: 0.5rem;
}

@media screen and (max-width: 1920px) {
  .ItemsTitle {
    font-size: var(--pbh2);
  }

  .ItemImage {
    width: 10rem;
    height: 10rem;
  }

  .Description {
    font-size: var(--pbh3);
  }

  .ScoreTitle {
    font-size: var(--pbh2);
  }

  .ScoreButton {
    font-size: var(--pbh3);
  }

  .MeetTitle {
    font-size: var(--pbh2);
  }
}

@media screen and (max-width: 1440px) {
  .ItemsTitle {
    font-size: var(--pmh2);
  }

  .Description {
    font-size: var(--pmh3);
  }

  .ScoreTitle {
    font-size: var(--pmh2);
  }

  .ScoreButton {
    font-size: var(--pmh3);
  }

  .MeetTitle {
    font-size: var(--pmh2);
  }
}

@media screen and (max-width: 1280px) {
  .ItemsTitle {
    font-size: var(--psh2);
  }

  .ItemImage {
    width: 7.5rem;
    height: 7.5rem;
  }

  .Description {
    font-size: var(--psh3);
  }

  .ScoreTitle {
    font-size: var(--psh2);
  }

  .ScoreButton {
    font-size: var(--psh3);
  }

  .MeetTitle {
    font-size: var(--psh2);
  }
}

@media screen and (max-width: 1023px) {
  .ItemsTitle {
    font-size: var(--th1);
  }

  .ItemImage {
    width: 5rem;
    height: 5rem;
  }

  .Description {
    font-size: var(--th2);
  }

  .ScoreTitle {
    font-size: var(--th1);
  }

  .ScoreButton {
    font-size: var(--th2);
  }

  .MeetTitle {
    font-size: var(--th1);
  }
}

@media screen and (max-width: 768px) {
  .ItemsSubContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .Item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    width: 75%;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .Image {
    width: 7.5rem;
    height: 7.5rem;
  }

  .ItemsTitle {
    font-size: var(--mh1);
  }

  .Description {
    padding-top: 0;

    font-size: var(--mh2);
    width: 50%;
  }

  .ScoreTitle {
    font-size: var(--mh1);
  }

  .ScoreButton {
    font-size: var(--mh2);
  }

  .MeetTitle {
    font-size: var(--mh1);
  }
}
