.BoardItem {
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background: none;
  overflow: hidden;
}

.BoardTitle {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  border: none;
  background: none;
  overflow: hidden;
}

.BoardItem:hover {
  background: #2a1d3e;
}

.BoardItem:focus {
  background: rgb(14 3 33 / 50%);
}

.BoardItem::after {
  background: rgb(14 3 33 / 50%);
}

.BoardItem1 {
  border: 2px solid #f3cd02;
}

.BoardItem2 {
  border: 2px solid #e8872d;
}

.BoardItem3 {
  border: 2px solid #717171;
}

.BoardItem4 {
  border: 2px solid #fff;
}

.Columns {
  display: grid;
  grid-template-columns: 20% 24% 18% 18% 17%;
  grid-template-rows: 5vh;
  padding-left: 2.3vw;
  margin-bottom: 2rem;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh2Modals);
  text-transform: uppercase;
  white-space: nowrap;
  color: #ffde53;
}

.Columns > span {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Separator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextContainer {
  display: grid;
  grid-template-columns: 10% 8% 10% 11.7% 6% 10% 8% 7.8% 12% auto;
  column-gap: 1.1%;
  grid-template-rows: 5rem;
  width: 100%;
  padding-left: 2.5%;
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh4);
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}

.TextContainer > span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ModalContent {
  border-radius: 8px;
  border: 4px solid white;
  width: fit-content;
}

.ModalDialog {
  margin-top: 0;
  margin-bottom: 0;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Thumbnail {
  height: 3rem;
}

.Ownership {
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 0.5vw;
  color: #ffde53;
  padding-left: 15%;
  padding-top: 1.5rem;
}

@media screen and (max-width: 1920px) {
  .TextContainer {
    font-size: var(--pbh4);
    grid-template-rows: 4rem;
  }

  .Columns {
    font-size: var(--pbh2Modals);
  }

  .Ownership {
    padding-top: 0.5rem;
  }
}

@media screen and (max-width: 1440px) {
  .TextContainer {
    font-size: var(--pmh4);
  }

  .Columns {
    font-size: var(--pmh2Modals);
  }
}

@media screen and (max-width: 1280px) {
  .TextContainer {
    font-size: var(--psh4);
    grid-template-rows: 3.5rem;
  }

  .Columns {
    font-size: var(--psh2Modals);
  }
}

@media screen and (max-width: 1024px) {
  .TextContainer {
    font-size: var(--th4);
    grid-template-rows: 3rem;
  }

  .Columns {
    font-size: var(--th3);
  }
}

@media screen and (max-width: 768px) {
  .TextContainer {
    font-size: var(--mh4);
    grid-template-rows: 2rem;
  }

  .Columns {
    font-size: var(--mh3);
  }
}
