.HomeContainer {
  background: linear-gradient(180deg, #522fa0, rgb(151 84 135 / 90%) 100%);
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: url('bg.png') center no-repeat;
  position: relative;
}
.Mask {
  -webkit-mask-image: url('mask.png');
  mask-image: url('mask.png');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 284px;
  width: 450px;
  margin-top: -38px;
  margin-left: -13px;
  display: flex;
}
.Mask video {
  margin-left: -27px;
}

.Banner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  z-index: 2;
}
.Kid {
  background: url('kid.png') center no-repeat;
  width: 372px;
  height: 303px;
  position: absolute;
  z-index: 2;
  bottom: 0;
}
.Character {
  max-width: 45vw;
}

.CallAction {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 34%;
  min-height: 100%;
  padding-top: 1.56rem;
  gap: 1rem;
}

.PlayButton {
  position: absolute;
  bottom: 40px;
  z-index: 99;
}
.BannerButton {
  font-size: var(--phh3);
  font-weight: 400;
}

.Text {
  padding-bottom: 1.5rem;
}

.Title {
  font-family: Pixeled;
  font-style: normal;
  font-size: var(--phh4);
  line-height: 2.825rem;
  text-transform: uppercase;
  color: #f68e5f;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.Description {
  margin-top: 2%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-size: var(--phh1);
  line-height: 5rem;
  color: #ffde53;
  flex: none;
  order: 1;
}

.ScrollContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  animation: bounce 0.75s ease infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(-15%);
  }

  30% {
    transform: translateY(-15%);
  }

  80% {
    transform: translateY(0%);
  }
}

.ScrollText {
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh4);
  line-height: 2.45rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.Line {
  padding: 0.5rem;
}

@media screen and (max-width: 1920px) {
  .BannerButton {
    font-size: var(--pbh3);
    line-height: 1.3rem;
  }

  .Title {
    font-size: var(--pbh4);
    line-height: 2.125rem;
  }

  .Description {
    font-size: var(--pbh1);
    line-height: 4rem;
  }

  .ScrollText {
    font-size: var(--pbh4);
    line-height: 2.25rem;
  }
}

@media screen and (max-width: 1440px) {
  .BannerButton {
    font-size: var(--pmh3);
    line-height: 1.3rem;
  }

  .Title {
    font-size: var(--pmh4);
    line-height: 2.125rem;
  }

  .Description {
    font-size: var(--pmh1);
    line-height: 3.25rem;
  }

  .ScrollText {
    font-size: var(--pmh4);
    line-height: 2.25rem;
  }
}

@media screen and (max-width: 1280px) {
  .CallAction {
    max-width: 100vw;
    padding-bottom: 2rem;
  }

  .BannerButton {
    font-size: var(--psh3);
    line-height: 1.3rem;
  }

  .Title {
    font-size: var(--psh4);
    line-height: 2.125rem;
  }

  .Description {
    font-size: var(--psh1);
    line-height: 3.25rem;
  }

  .ScrollText {
    font-size: var(--psh4);
    line-height: 2.25rem;
  }
}

@media screen and (max-width: 1023px) {
  .BannerButton {
    font-size: var(--th3);
    line-height: 1.2rem;
  }

  .Title {
    font-size: var(--th4);
    line-height: 2rem;
    text-align: center;
  }

  .Description {
    font-size: var(--th1);
    line-height: 3rem;
  }

  .ScrollText {
    font-size: var(--th4);
    line-height: 2rem;
  }

  .Banner {
    flex-direction: column;
  }

  .Character {
    max-width: 90vw;
    padding: 4%;
  }

  .Text {
    width: 70vw;
    padding-top: 4%;
    text-align: center;
  }

  .CallAction {
    align-items: center;
  }
}

@media screen and (min-width: 1023px) {
  .Banner {
    max-height: 45vh;
  }
}

@media screen and (max-width: 768px) {
  .BannerButton {
    font-size: var(--mh3);
    line-height: 1.1rem;
  }

  .Title {
    font-size: var(--mh4);
    line-height: 1.9rem;
  }

  .Description {
    font-size: var(--mh1);
    line-height: 2.75rem;
  }

  .ScrollText {
    font-size: var(--mh4);
    line-height: 1.9rem;
  }
}
