.GameHomeContainer {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgb(2 0 6),
    rgb(18 0 61 / 90%) 14.84%,
    rgb(29 4 89 / 90%) 21.91%,
    rgb(39 9 114 / 90%) 29.12%,
    rgb(61 25 130 / 90%) 38.32%,
    rgb(142 64 124 / 90%) 135.67%
  );
}

.GameContainer {
  position: relative;
  width: 85vw;
  height: 47.8125vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d9d9d924;
  border: 0.13rem solid #ffde53;
  box-shadow: 0 0.43px 0.63px rgb(0 0 0 / 30%);
  border-radius: 1.93rem;
  overflow: hidden;
}

.ControlsContainer {
  padding-top: 40px;
  font-family: 'Pixeled';
  color: #fff;
  width: 100%;
  max-width: 600px;
}
.ControlsContainer h5 {
  text-align: center;
}
.ControlItems {
  margin: 40px 0;
  font-family: 'xpaider pixel explosion 02';
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  font-size: 0.75rem;
}
.ControlItemsColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.ControlItemsRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.ControlItem {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 10px;
}
.ControlItems span {
  background: #5a7289;
  padding: 10px 15px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #2c3743;
}
.ControlItems em {
  font-style: normal;
  font-size: 2rem;
}
