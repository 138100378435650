.PassCardContainerClickable {
  width: 13rem;
  height: 13rem;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #0e0321;
  box-shadow: 0 11.2461px 29.9895px rgb(50 53 64 / 8%),
    0 11.2461px 29.9895px rgb(50 53 64 / 12%);
  border-radius: 5.62px;
  border: 0.1rem solid white;
}

.PassCardContainerClickable:hover {
  scale: 1.1;
  animation: onSelect 0.2s linear forwards;
  cursor: pointer;
}

@keyframes onSelect {
  0% {
    background: #0e0321;
    scale: 1;
  }
  100% {
    background: #370860;
    scale: 1.01;
  }
}

.PassCardContainer {
  width: 13rem;
  height: 13rem;
  margin: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #0e0321;
  box-shadow: 0 11.2461px 29.9895px rgb(50 53 64 / 8%),
    0 11.2461px 29.9895px rgb(50 53 64 / 12%);
  border-radius: 5.62px;
  border: 0.1rem solid white;
}

.Title {
  padding-top: 10%;
  padding-left: 5%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.15em;
  color: #fff;
}

.Subtitle {
  padding-bottom: 1%;
  padding-left: 5%;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 0.15em;
  color: #fff;
}

.PassCardSubcontainer {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 3.62px;
  border: 0.1rem solid white;
}

.Description {
  padding-left: 5%;
  display: flex;
  flex-direction: column;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  color: #ffde53;
}

.ViewTotalFights {
  padding-left: 5%;
  text-align: center;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.15em;
  color: #ffde53;
}

.PassCardCheckout {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.Spinner {
  max-width: 1rem;
  max-height: 1rem;
}

.PassCardAmount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 5rem;
  height: 2rem;

  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.15em;

  color: #fff;
  background: #5532aa;
  border-radius: 7px;
  border: 0.15rem solid #12a9a9;
}

.PassCardAmount > span {
  text-align: center;
  width: 2rem;
}

.PassCardAmount > button {
  text-align: center;
  width: 1.5rem;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  flex: 0 0;
  color: white;
  background: none;
  border: none;
}

.AmountText {
  padding-top: 0.1rem;
}

.PassCardButton {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding-top: 0.2rem;

  width: 6em;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  text-align: center;
  letter-spacing: 0.01em;
  color: black;
  border: none;
  border-radius: 7px;
  background-color: #cf8;
  text-transform: uppercase;
}

.PassCardButton:hover {
  background-color: #cf8;
}

.PassCardDisabledButton:hover {
  color: black;
  background-color: gray;
}

.PassCardButton:focus {
  background-color: #cf8;
}

.PassCardDisabledButton:focus {
  color: black;
  background-color: gray;
}
