.LoaderContainer {
  position: absolute;
  z-index: 10000;
  background: #522fa0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: colorShift 3s linear infinite;
}

@keyframes colorShift {
  0% {
    background: rgb(151 84 135);
  }

  25% {
    background: #522fa0;
  }

  30% {
    background: rgb(151 84 135);
  }

  80% {
    background: #522fa0;
  }
}
