.SkillContainer {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: auto auto;
  column-gap: 2rem;
}

.SkillCardContainer {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-template-rows: auto auto;
  column-gap: 2rem;
}

.SkillLine {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5%;
  justify-content: flex-start;
  text-align: center;
  font-family: Pixeled;
  font-style: normal;
  font-weight: 400;
  font-size: var(--th4);
  text-transform: uppercase;
  color: #fff;
}

.SkillLine > span {
  text-align: start;
  width: 5rem;
}

.Bars {
  width: max-content;
}

.SkillLine > img {
  padding-left: 0;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
}

@media screen and (max-width: 1024px) {
  .SkillContainer {
    grid-template-columns: 40%;
    grid-template-rows: auto auto auto auto;
    column-gap: 2rem;
  }
}
