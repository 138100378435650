.LaunchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: radial-gradient(
    110.15% 110.15% at 49.98% -10.15%,
    rgba(85, 50, 170, 0.2) 0%,
    rgba(204, 255, 136, 0.2) 100%
  );
  padding: 5%;
}

.ParallaxBG {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 27vh;
  padding-right: 38vw;
}

.BackgroundBack {
  z-index: 1;
  height: 60vh;
  opacity: 0.15;
  transform: scale(1);
}

.BackgroundFront {
  position: absolute;
  top: 42vh;
  z-index: 2;
  height: 60vh;
  opacity: 0.15;
  transform: scale(0.5);
}

.TopContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  min-height: 40vh;
  padding: 3%;
}

.TopLine {
  min-width: 30vw;
  height: 15vh;
}

.CounterContainer {
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  min-height: 33vh;
  flex-shrink: 0;
  width: 28rem;
  margin-left: 5%;
  margin-right: 5%;
}

.CounterTitle {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);
  text-align: center;
  text-transform: uppercase;

  color: #09e1ff;

  mix-blend-mode: normal;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.CounterTimer {
  display: flex;
  flex-direction: column;
  padding: 5%;

  font-family: 'Pixeled';
  font-style: normal;
  font-weight: 400;
  font-size: 2.7rem;
  line-height: 7rem;

  text-transform: uppercase;

  color: #04fda3;
}

.TimerText {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 20rem;

  font-family: 'Pixeled';
  font-style: normal;
  font-weight: 400;
  font-size: 0.825rem;
  line-height: 1.81rem;

  text-transform: uppercase;

  color: #04fda3;
}

.CounterDescription {
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh2);

  text-transform: uppercase;

  color: #7cffc2;
}

.SubContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 60vh;
}

.Lincoln {
  width: max-content;
  height: max-content;
}

.Features {
  max-width: 40%;
}

.FeatureItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.FeatureItem > img {
  width: 27px;
  height: 27px;
  margin-top: 13px;
}

.FeatureText {
  margin: 3%;

  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);

  color: #ffffff;
}

.Line {
  padding: 0.5rem;
}

@media screen and (max-width: 1920px) {
  .CounterTitle {
    font-size: var(--pbh2);
    line-height: 2.3rem;
  }

  .CounterTimer {
    font-size: 2.5rem;
    line-height: 7rem;
  }

  .TimerText {
    font-size: 0.625rem;
    line-height: 1.81rem;
  }

  .CounterDescription {
    font-size: var(--pbh2);
    line-height: 1.94rem;
  }

  .FeatureText {
    font-size: var(--pbh3);
    line-height: 1.75rem;
  }
}

@media screen and (max-width: 1440px) {
  .CounterTitle {
    font-size: var(--pmh2);
    line-height: 2.3rem;
  }

  .CounterTimer {
    font-size: 2.5rem;
    line-height: 7rem;
  }

  .TimerText {
    font-size: 0.625rem;
    line-height: 1.81rem;
  }

  .CounterDescription {
    font-size: var(--pmh2);
    line-height: 1.94rem;
  }

  .FeatureText {
    font-size: var(--pmh3);
    line-height: 1.75rem;
  }
}

@media screen and (max-width: 1280px) {
  .CounterTitle {
    font-size: var(--psh2);
    line-height: 2.3rem;
  }

  .CounterTimer {
    font-size: 2.5rem;
    line-height: 7rem;
  }

  .TimerText {
    font-size: 0.625rem;
    line-height: 1.81rem;
  }

  .CounterDescription {
    font-size: var(--psh2);
    line-height: 1.94rem;
  }

  .FeatureText {
    font-size: var(--psh3);
    line-height: 1.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .CounterTitle {
    font-size: var(--th1);
    line-height: 2.3rem;
  }

  .CounterTimer {
    font-size: 2.3rem;
    line-height: 7rem;
  }

  .TimerText {
    font-size: 0.525rem;
    line-height: 1.81rem;
  }

  .CounterDescription {
    font-size: var(--th1);
    line-height: 1.94rem;
  }

  .FeatureText {
    font-size: var(--th2);
    line-height: 1.75rem;
  }

  .SubContainer {
    flex-direction: column;
  }
  .Features {
    max-width: 60%;
  }
  .FeatureItem {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 5%;
  }
  .Lincoln {
    padding: 7.5%;
  }
}

@media screen and (max-width: 768px) {
  .CounterTitle {
    font-size: var(--mh1);
    line-height: 2.3rem;
  }

  .CounterTimer {
    font-size: 2.1rem;
    line-height: 7rem;
  }

  .TimerText {
    font-size: 0.425rem;
    line-height: 1.81rem;
  }

  .CounterDescription {
    font-size: var(--mh1);
    line-height: 1.94rem;
  }

  .FeatureText {
    font-size: var(--mh2);
    line-height: 1.75rem;
  }
}
