.CarouselContainer {
  width: 85%;
  height: max-content;
  align-self: center;
}

.CarouselContainer > button {
  margin: -4%;
}

.CarouselItem {
  margin-top: 0;
  margin-bottom: 6rem;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: center;
}
