.HomeContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 3rem;

  background: linear-gradient(
    180deg,
    #0e0321 2.12%,
    rgb(18 0 61 / 90%) 14.84%,
    rgb(29 4 89 / 90%) 21.91%,
    rgb(39 9 114 / 90%) 29.12%,
    rgb(61 25 130 / 90%) 38.32%,
    rgb(151 84 135 / 90%) 135.67%
  );
}

.Title {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  width: 90%;
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: 'xpaider pixel explosion 02';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh2Modals);
  letter-spacing: 0.03em;
  color: #ffffff;
}

.BuyMessage {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);
  letter-spacing: 0.03em;
  text-transform: uppercase;
  white-space: nowrap;
  color: #ffffff;
  margin: 2.5%;
}

.BuyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15em;
  height: 3em;
  white-space: nowrap;
  margin: 2.5%;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0.2rem solid black;
  border-radius: 7px;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: black;
  background-color: #cf8;
  text-decoration: none;
  text-transform: uppercase;
}

.BuyButton:hover {
  color: black;
}

@media screen and (max-width: 1920px) {
  .BuyMessage {
    font-size: var(--pbh3);
  }

  .Title {
    font-size: var(--pbh2Modals);
  }
}

@media screen and (max-width: 1440px) {
  .BuyMessage {
    font-size: var(--pmh3);
  }

  .Title {
    font-size: var(--pmh2Modals);
  }
}

@media screen and (max-width: 1280px) {
  .Title {
    font-size: var(--psh2Modals);
  }
}

@media screen and (max-width: 1023px) {
  .Title {
    font-size: var(--th2Modals);
  }
}

@media screen and (max-width: 768px) {
  .BuyMessage {
    font-size: var(--mh3);
  }

  .Title {
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    font-size: var(--mh2Modals);
    height: 5rem;
  }
}
