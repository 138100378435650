.Loader {
  width: 4vmin;
  margin: 2.5%;
  align-self: center;
  justify-self: center;
}

.ScreenLoader {
  width: 5vw;
  align-self: center;
  justify-self: center;
}

.LoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  height: 100%;
}

.ImageLoader {
  max-width: 4vmin;
  margin: 1%;
}
