.MarketHomeBackground {
  min-height: 80vh;
}

.MarketHomeContainer {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgb(14 3 33),
    rgb(18 0 61 / 90%) 14.84%,
    rgb(29 4 89 / 90%) 21.91%,
    rgb(39 9 114 / 90%) 29.12%
  );
}

.BannerContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 2.5%;
}

.Title {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  width: 90%;
  margin-top: 5%;
  margin-bottom: 5%;
  text-transform: uppercase;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh3);
  letter-spacing: 0.03em;

  color: #ffffff;
}

.Subtitle {
  width: 50%;
  margin-bottom: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh4);
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
}

.BuyRow {
  display: flex;
  width: 50%;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.BuyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15em;
  height: 3em;
  white-space: nowrap;
  margin-left: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 0.2rem solid black;
  border-radius: 7px;
  font-family: 'xpaider pixel explosion 02';
  font-style: normal;
  font-weight: 400;
  font-size: var(--phh5);
  letter-spacing: 0.01em;
  color: black;
  background-color: #cf8;
  text-decoration: none;
  text-transform: uppercase;
}

.BuyButton:hover {
  color: black;
}

.Carousel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1920px) {
  .Title {
    font-size: var(--pbh2Modals);
  }
  .BuyButton {
    font-size: var(--pbh4);
  }
  .Subtitle {
    font-size: var(--pbh3);
  }
}

@media screen and (max-width: 1440px) {
  .Title {
    font-size: var(--pmh2Modals);
  }
  .BuyButton {
    font-size: var(--pmh4);
  }
  .Subtitle {
    font-size: var(--pmh3);
  }
}

@media screen and (max-width: 1280px) {
  .Title {
    font-size: var(--psh2Modals);
  }
  .BuyButton {
    font-size: var(--psh4);
  }
  .Subtitle {
    font-size: var(--psh3);
  }
}

@media screen and (max-width: 1023px) {
  .Title {
    font-size: var(--th2Modals);
  }
  .BuyButton {
    font-size: var(--th4);
  }
  .Subtitle {
    font-size: var(--th3);
  }
}

@media screen and (max-width: 768px) {
  .Title {
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    font-size: var(--mh2Modals);
    height: 5rem;
  }
  .BuyButton {
    font-size: var(--mh4);
  }
  .Subtitle {
    font-size: var(--mh3);
  }
}
